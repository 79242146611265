<route>
{
  "meta": {
    "auth": "refundApplyRecordList"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">订单编号</span>
          <el-input
            class="searchInput"
            placeholder="订单编号"
            @keyup.enter.native="getList"
            oninput="value=value.replace(/[^0-9]/g,'')"
            v-model="search.orderId"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商品名称</span>
          <el-input
            class="searchInput"
            placeholder="商品名称"
            @keyup.enter.native="getList"
            v-model.trim="search.productName"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">退款状态</span>

          <el-select class="searchInput" v-model="status" placeholder="请选择">
            <el-option label="全部" value=""> </el-option>
            <el-option label="已退款" value="1"> </el-option>
            <el-option label="已拒绝" value="2"> </el-option>
            <el-option label="已取消" value="3"> </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">购买人昵称</span>
          <el-input
            class="searchInput"
            placeholder="请输入购买人昵称"
            @keyup.enter.native="getList"
            v-model.trim="search.memberName"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">购买人ID</span>
          <el-input
            class="searchInput"
            placeholder="请输入购买人ID "
            @keyup.enter.native="getList"
            oninput="value=value.replace(/[^0-9]/g,'')"
            v-model="search.memberId"
            clearable
          >
          </el-input>
        </el-col>

        <el-col :span="8">
          <span class="searchText">退款原因</span>

          <el-cascader
            :options="searchRejectReasonList"
            class="searchInput"
            clearable
            @change="option => handleChange(option, search, 'refundReasonId')"
          ></el-cascader>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">会员手机号</span>
          <el-input
            class="searchInput"
            placeholder="请输入会员手机号"
            @keyup.enter.native="getList"
            v-model.trim="search.mobile"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">核销码</span>
          <el-input
            class="searchInput"
            placeholder="请输入核销码 "
            @keyup.enter.native="getList"
            v-model="search.checkCode"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">销售名称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入销售名称 "
            v-model="search.saleName"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">抽佣类型</span>
          <el-select v-model="search.exchangeType" placeholder="请选择" class="searchInput">
            <el-option label="全抽佣" value="全抽佣"> </el-option>
            <el-option label="半抽佣" value="半抽佣"> </el-option>
            <el-option label="无抽佣" value="无抽佣"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <span class="searchText">销售类型</span>
          <el-select v-model="search.saleType" placeholder="请选择" class="searchInput">
            <el-option label="广告" value="广告"> </el-option>
            <el-option label="商城" value="商城"> </el-option>
            <el-option label="吃团团" value="吃团团"> </el-option>
            <el-option label="补录" value="补录"> </el-option>
            <el-option label="其它" value="其它"> </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <span class="searchText">商户名称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入商户名称 "
            v-model="search.businessName"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="11">
          <span class="searchText">提交时间</span>
          <el-date-picker
            class="searchInput"
            v-model="search.time"
            value-format="timestamp"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-form inline>
            <bm-areaSelect v-model="areaId"></bm-areaSelect>
          </el-form>
        </el-col>
        <el-col :span="5">
          <el-button type="info" @click="reset" style="margin:10px;">重置</el-button>
          <el-button type="primary" @click="getList" style="margin:10px;"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
        <el-button size="mini" style="float: right; margin-right: 10px" type="success" @click="downLoadExcel"
          >导出退款记录</el-button
        >
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%" class="table">
        <el-table-column v-for="col in Object.keys(labels)" :key="col" :prop="col" :label="labels[col]" align="center">
          <template slot-scope="{ row }">
            <template v-if="col === 'info'">
              <div class="product-info">
                <img :src="row[col].skuPic | thumbMedium" />
                <div class="info">{{ row[col].skuName }}</div>
              </div>
            </template>
            <template v-else-if="col === 'refundInfo'">
              <div class="item">实付总金额：{{ row[col].actualPayMoney }}</div>
              <div class="item">商品售价：{{ row[col].skuMoney }}</div>
            </template>
            <template v-else-if="col === 'applyRefundTime'">
              {{ new Date(row[col]).toLocaleString() }}
            </template>

            <template v-else-if="col === 'areaId'">
              {{ row[col] | formatArea }}
            </template>
            <template v-else-if="col === 'saleNameList' || col === 'saleTypeList' || col === 'exchangeTypeList'">
              <span v-for="(item, index) in row[col]" :key="item"
                >{{ item }}{{ row[col].length == index + 1 ? '' : ',' }}</span
              >
            </template>
            <template v-else-if="col === 'verifyStatus'">
              {{ row[col] === 1 ? '已退款' : row[col] === 2 ? '已拒绝' : '已取消' }}
            </template>
            <template v-else>{{ row[col] }}</template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small"> 详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="退款记录导出"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 80px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <div style="margin-top: 20px">
          <span style="display: inline-block; width: 80px"> 退款原因</span>
          <el-cascader
            :options="searchRejectReasonList"
            class="searchInput"
            clearable
            @change="option => handleChange(option, download, 'refundReasonId')"
          ></el-cascader>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchRejectReasonList: [],
      status: '',
      areaId: '',
      search: {
        orderId: '',
        productName: '',
        memberId: '',
        memberName: '',
        refundReasonId: '',
        checkCode: '',
        mobile: '',
        saleName: '',
        saleType: '',
        exchangeType: '',
        businessName: '',
        time: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      },
      labels: {
        orderId: '交易单号',
        info: '款式信息',
        memberName: '购买人昵称',
        areaId: '地区',
        refundReasonType: '退款分类',
        memberId: '购买人ID',
        refundNum: '退款数量',
        memberMonthRefundSort: '本月历史退款数量',
        refundReason: '退款原因',
        refundInfo: '退款信息',
        verifyStatus: '退款状态',
        applyRefundTime: '发起时间',
        saleNameList: '销售名称',
        saleTypeList: ' 销售类型',
        exchangeTypeList: ' 抽佣类型',
        businessName: ' 商户名称'
      },
      download: {
        show: false,
        url: '',
        time: [],
        refundReasonId: ''
      },
      list: [],
      hideStatusParamList: {
        refundItemList: [],
        deductionRatio: '',
        refundStatus: '',
        denialNotes: '此商品不能退款',
        verifyStatus: 1
      }
    }
  },
  methods: {
    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/orderRefund?',
        time: [],
        refundReasonId: ''
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let status = `1&verifyStatusList=2&verifyStatusList=3`

      let time = this.download.time.length
        ? `&applyRefundTimeEnd=${this.download.time[1]}&applyRefundTimeStart=${this.download.time[0]}`
        : ''

      let params = `&verifyStatusList=${status}${time}&refundReasonId=${this.download.refundReasonId}`

      this.$http
        .get(this.download.url + params)
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    getRefundReasonList() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReason/list')
        .then(res => {
          let reasonType = {}
          this.searchRejectReasonList = []
          res.map(v => {
            if (!reasonType[v.typeName]) reasonType[v.typeName] = []
            reasonType[v.typeName].push({
              value: v.id,
              label: v.name
            })
          })
          for (let key in reasonType) {
            this.searchRejectReasonList.push({
              value: key,
              label: key,
              children: reasonType[key]
            })
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 改变退款原因
    handleChange(option, obj, name) {
      obj[name] = option[1] ? option[1] : ''
    },
    check(row) {
      this.$router.push(
        `/financial/refundApply/refundParticulars?id=${row.orderId}&refundId=${row.id}&memberId=${row.memberId}&type=refundDetail`
      )
    },
    reset() {
      this.status = ''
      this.search = {
        orderId: '',
        productName: '',
        memberId: '',
        refundReasonId: '',
        memberName: '',
        checkCode: '',
        mobile: '',
        saleName: '',
        saleType: '',
        exchangeType: '',
        businessName: '',
        time: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      }
      this.areaId = ''
      this.getList(false, true)
    },
    getList(val, reset) {
      this.loading = true
      // 判断是否是分页还是普通搜索
      this.currentPage = val === true ? this.currentPage : 1
      if (this.search.time) {
        this.search.applyRefundTimeStart = this.search.time[0]
        this.search.applyRefundTimeEnd = this.search.time[1]
      } else {
        this.search.applyRefundTimeStart = ''
        this.search.applyRefundTimeEnd = ''
      }

      let { time, ...payload } = this.search

      let status = `verifyStatusList=1&verifyStatusList=2&verifyStatusList=3`
      if (this.status === '') {
        let status = `verifyStatusList=1&verifyStatusList=2&verifyStatusList=3`
      } else if (this.status === '1') {
        status = `verifyStatusList=1`
      } else if (this.status === '2') {
        status = `verifyStatusList=2`
      } else {
        status = `verifyStatusList=3`
      }
      let config =
        reset === true
          ? {}
          : {
              headers: {
                ['Bm-Area-Id']: this.areaId
              }
            }
      let params = `page=${this.currentPage}&${status}&size=${this.pageSize}&applyRefundTimeEnd=${payload.applyRefundTimeEnd}&applyRefundTimeStart=${payload.applyRefundTimeStart}&memberId=${payload.memberId}&memberName=${payload.memberName}&orderId=${payload.orderId}&productName=${payload.productName}&refundReasonId=${payload.refundReasonId}&mobile=${payload.mobile}&checkCode=${payload.checkCode}&saleName=${payload.saleName}&saleType=${payload.saleType}&exchangeType=${payload.exchangeType}&businessName=${payload.businessName}`

      this.$http
        .get('boom-center-search-service/sysAdmin/orderRefund/page?' + params, config)
        .then(res => {
          this.total = res.total
          res.list.map(item => {
            item.info = {
              skuPic: item.skuPic,
              skuName: item.skuName
            }
            item.refundInfo = {
              actualPayMoney: item.actualPayMoney,
              skuMoney: item.skuMoney
            }
          })
          this.list = res.list
          this.hideStatusParamList.refundItemList = []
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getRefundReasonList()
  }
}
</script>

<style lang="less" scoped>
.table {
  .product-info {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
    }
    .info {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.searchInput,
.btn {
  margin: 10px;
}
</style>
